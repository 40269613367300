import React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import { getFluidGatsbyImage } from 'gatsby-source-sanity'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import styled from 'styled-components'

import { imageLinksHandler } from './handleRichTextLinks'
import DonateButton from '../components/donate/button'

const ImageNonWrapperStyles = styled.div.attrs(props => {
  let margin = '0'
  let maxWidth = '100%'

  if (props.stats) {
    const { maxWidth: propsMaxWidth, alignment } = props.stats
    maxWidth = `${propsMaxWidth}px`

    if (alignment === 'center') {
      margin = '0 auto'
    } else if (alignment === 'right') {
      margin = '0 0 0 auto'
    }
  }

  return {
    maxWidth,
    margin,
  }
})`
  .image-container {
    max-width: ${props => props.maxWidth};
    margin: ${props => props.margin};
    margin-bottom: 20px;
  }
`

const ImageWrapTextStyles = styled.div.attrs(props => {
  let margin = '0'
  let align = 'left'
  let contentMargin = '0 10px 0 0'
  let maxWidth = '100%'
  const marginAmt = '10px'

  if (props.stats) {
    const { maxWidth: propsMaxWidth, alignment } = props.stats
    align = alignment
    maxWidth = `${propsMaxWidth}px`

    contentMargin = alignment === 'right' ? '0 10px 0 0' : '0 0 0 10px'

    if (alignment === 'center') {
      margin = '0 auto'
    } else if (alignment === 'right') {
      margin = '0 0 0 auto'
    }
  }

  return {
    floatWidth: maxWidth,
    floatAlign: align,
    normalMargin: margin,
    floatMargin: {
      left: align === 'right' ? marginAmt : '0',
      right: align !== 'right' ? marginAmt : '0',
    },
    floatContentMargin: contentMargin,
  }
})`
  .image-container {
    margin: ${prop => prop.normalMargin};
    /* max-width: ${prop => prop.floatWidth}; */
    max-width: 100%;
    margin-bottom: 20px;
  }

  .wrapper-content {
    p {
      padding: 0;
      margin: 0;
    }
  }

  @media all and (min-width: 700px) {
    overflow: auto;
    margin-bottom: 10px;
    .image-container {
      float: ${props => props.floatAlign};
      width: ${props => props.floatWidth};
      margin: 0 0;
      margin-left: ${props => props.floatMargin.left};
      margin-right: ${props => props.floatMargin.right};
    }
  }
`

const RichTextStyle = styled.div`
  .right {
    text-align: right;
    justify-content: right;
  }

  .left {
    text-align: left;
    justify-content: left;
  }

  .center {
    text-align: center;
    justify-content: center;
  }

  .justify {
    text-align: justify;
  }
`

const serializers = {
  types: {
    inlineImage(props) {
      const { node, options } = props

      const imageId = node.image.asset._ref || node.image.asset.id
      const config = options
      const altText = node.alt
      const { imageStats, imageLink } = node

      const image = getFluidGatsbyImage(imageId, {}, config)
      const imgElement = <Img fluid={image} alt={altText} />

      let finalImgEl
      if (imageLink) {
        finalImgEl = imageLinksHandler(imageLink, imgElement, imageStats)
      } else {
        finalImgEl = imgElement
      }

      if (imageStats && imageStats.content && imageStats.textWrap) {
        return (
          <ImageWrapTextStyles stats={imageStats}>
            <div className="image-container">{finalImgEl}</div>
            <div className="wrapper-content">
              <ProcessRichText curProp={imageStats} />
            </div>
          </ImageWrapTextStyles>
        )
      }
      return (
        <ImageNonWrapperStyles stats={imageStats}>
          <div className="image-container">{finalImgEl}</div>
        </ImageNonWrapperStyles>
      )
    },
    pioDonate({ node }) {
      console.log('🚀 - node:', node)
      return <DonateButton title={node.title} alignment={node.alignment} />
    },
    block(props) {
      const { node } = props
      const type = node._style || node.style

      const map = ['left', 'center', 'right', 'justify']

      const classListArr = node.children.reduce((classArr, child) => {
        child.marks.forEach(mark => {
          const addProp = map.includes(mark)

          if (addProp && !classArr.includes(mark)) {
            classArr.push(mark)
          }
        })

        return classArr
      }, [])

      const hasText = !!node.children.find(prop => prop.text)

      if (!hasText) {
        return <br />
      }

      switch (type) {
        case 'h1':
          return <h1 className={classListArr}>{props.children}</h1>
        case 'h2':
          return <h2 className={classListArr}>{props.children}</h2>
        case 'h3':
          return <h3 className={classListArr}>{props.children}</h3>
        case 'h4':
          return <h4 className={classListArr}>{props.children}</h4>
        case 'h5':
          return <h5 className={classListArr}>{props.children}</h5>
        case 'h6':
          return <h6 className={classListArr}>{props.children}</h6>
        case 'blockquote':
          return (
            <blockquote className={classListArr}>{props.children}</blockquote>
          )
        default:
          return <p className={classListArr}>{props.children}</p>
      }
    },
  },
  marks: {
    left({ children }) {
      return children
    },
    center({ children }) {
      return children
    },
    right({ children }) {
      return children
    },
    justify({ children }) {
      return children
    },
    link({ children, mark }) {
      if (mark.blank) {
        return (
          <a
            className="dark"
            target="_blank"
            rel="noopener noreferrer"
            href={mark.href}
          >
            {children}
          </a>
        )
      }
      return (
        <a className="dark" href={mark.href}>
          {children}
        </a>
      )
    },
    internalLink({ mark, children }) {
      const type = Object.keys(mark).filter(m => !m.includes('_'))[0]
      const id = mark[type]._ref

      return (
        <Link className="dark" rel="canonical" to={`/${type}/${id}`}>
          {children}
        </Link>
      )
    },
    fileLink({ children, mark }) {
      return (
        <a
          className="dark"
          href={mark.asset.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {children}
        </a>
      )
    },
    strike({ children }) {
      return <span>{children}</span>
    },
  },
}

const ProcessRichText = ({
  curProp,
  debug = false,
  Styles = RichTextStyle,
}) => (
  <Styles>
    <BlockContent
      blocks={curProp.content}
      dataset="production"
      projectId="hgayog7e"
      serializers={serializers}
      imageOptions={{ maxWidth: 100 }}
    />
    {debug ? <pre>{JSON.stringify(curProp.content, null, 2)}</pre> : ''}
  </Styles>
)

export default ProcessRichText

export { ImageNonWrapperStyles, ImageWrapTextStyles }
